import { ClientLogo } from '@/root/ui/shared'
import { HeaderNavBarProps, Locale } from '@/root/shared-types'
import { MenuItem } from '@/root/ui/bluewave'
import { TopMenuItemsContainer } from '@/root/components/bluewave/TopMenuItemsContainer/TopMenuItemsContainer'
import { LangSwitcher } from '@/root/modules/bluewave/AppLayout/components/LangSwitcher/LangSwitcher'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useConfig } from '@/root/shared-hooks'

export const TopHeaderMenu = ({ menuItems }: HeaderNavBarProps) => {
  const { locale, locales, asPath } = useRouter()
  // TODO: remove when all corresponding pages will be done
  const { oldBoraOrigin } = useConfig()

  const displayLangSwitcher = locale !== undefined && locales !== undefined
  return (
    <div className="flex items-center justify-center">
      <div className="flex h-16 w-5/6 max-w-screen-xl justify-between">
        <Link href="/" className="flex justify-center">
          <ClientLogo clientName="bluewave" width={170} height={30} />
        </Link>
        <TopMenuItemsContainer>
          {menuItems.map(({ id, subject, link, modal }) => (
            <MenuItem href={!modal ? `${oldBoraOrigin}/${locale}/#${link}` : ''} text={subject} key={id} type={'top'} />
          ))}
          {displayLangSwitcher && (
            <LangSwitcher locales={locales as Locale[]} locale={locale as Locale} asPath={asPath} />
          )}
        </TopMenuItemsContainer>
      </div>
    </div>
  )
}
