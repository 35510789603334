import { FC } from 'react'
import classNames from 'classnames'

interface ICheckboxProps {
  label: string
  checked: boolean
  onChange(value: boolean): void
  disabled?: boolean
  className?: string
}

const Checkbox: FC<ICheckboxProps> = ({ label, checked, onChange, disabled = false, className }) => {
  return (
    <label
      className={classNames(
        {
          'cursor-not-allowed text-adm-gray-450 fill-adm-gray-450': disabled,
          'cursor-pointer fill-adm-red-700': !disabled,
        },
        `flex ${className}`
      )}
    >
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        className="absolute appearance-none opacity-0"
        onChange={() => onChange(!checked)}
      />
      <svg className="z-10 mr-[10px] h-6 w-6 flex-none" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
        {checked ? (
          <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        ) : (
          <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
        )}
      </svg>
      <span className="text-base font-normal">{label}</span>
    </label>
  )
}

export default Checkbox
