import Link from 'next/link'
import { useState } from 'react'
import { UKFlagIcon } from '@/root/ui/bluewave/icons/UKFlagIcon'
import { SpainFlagIcon } from '@/root/ui/bluewave/icons/SpainFlagIcon'
import { DropdownButton } from '@/root/ui/bluewave'
import { DropdownContainer } from '@/root/ui/bluewave/DropdownContainer'
import { Locale } from '@/root/shared-types'

interface LangSwitcherProps {
  locales: Locale[]
  locale: Locale
  asPath: string
}
export const LangSwitcher = ({ locales, locale, asPath }: LangSwitcherProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const buttonContent: { [key: string]: { icon: JSX.Element; text: string } } = {
    en: {
      icon: <UKFlagIcon />,
      text: 'English',
    },
    es: {
      icon: <SpainFlagIcon />,
      text: 'Español',
    },
  }
  const onToggleDropDown = () => setIsOpen((open) => !open)

  return (
    <div className="relative inline-block text-left">
      <DropdownButton onClick={onToggleDropDown}>
        {buttonContent[locale].icon}
        {buttonContent[locale].text}
      </DropdownButton>
      {isOpen && (
        <DropdownContainer onClose={onToggleDropDown}>
          {locales.map((lcl) => (
            <Link
              href={asPath}
              locale={lcl}
              key={lcl}
              onClick={onToggleDropDown}
              className="flex items-center justify-items-start gap-2 text-lg font-bold text-bw-primary-mediumLight "
            >
              {buttonContent[lcl].icon}
              {buttonContent[lcl].text}
            </Link>
          ))}
        </DropdownContainer>
      )}
    </div>
  )
}
