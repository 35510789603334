import { FC } from 'react'
import { TicketProps } from '@/root/shared-types/reservation/reservation-types'
import { PrintedTicketPart } from '@/root/modules/bluewave/PrintedTicket/compoonents/PrintedTicketPart/PrintedTicketPart'
import Divider from '@/root/modules/admaritime/PrintedTicket/components/Divider/Divider'
import { TermsAndConditions } from '@/root/modules/bluewave/PrintedTicket/compoonents/TermsAndConditions/TermsAndConditions'
import { useRouter } from 'next/router'
import { getTermsByLocale } from '@/root/shared-helpers'
import { PaymentInfo } from '@/root/modules/bluewave/PrintedTicket/compoonents/PaymentInfo/PaymentInfo'
import { Locale } from '@/root/shared-types'

const PrintedTicket: FC<TicketProps> = ({
  ticketData: {
    there,
    back,
    customerInfo,
    reservationId,
    status,
    isRoundTrip,
    terms,
    paymentInfo: { totalPrice },
  },
}) => {
  const { locale } = useRouter()
  const currentLangTerms = getTermsByLocale(terms, locale as Locale)
  return (
    <>
      <div className="rounded-lg border">
        <PrintedTicketPart reservationId={reservationId} status={status} {...there} customerInfo={customerInfo} />

        {isRoundTrip && (
          <div className="relative">
            <Divider cancelled={false} />
            <PrintedTicketPart reservationId={reservationId} status={status} {...back} />
          </div>
        )}
      </div>

      {status === 'OK' && currentLangTerms?.ticketTerms && (
        <TermsAndConditions conditionsInnerHTML={currentLangTerms.ticketTerms} />
      )}

      {status === 'BOOKED' && <PaymentInfo ticketNumber={reservationId} paymentAmount={totalPrice.amountFormatted} />}
    </>
  )
}

export default PrintedTicket
