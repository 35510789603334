import { HeaderNavBarProps } from '@/root/shared-types'
import { MenuItem } from '@/root/ui/bluewave'
import { MainHeaderNavBarContainer } from '@/root/components/bluewave/MainHeaderNavBarContainer/MainHeaderNavBarContainer'
import { useConfig } from '@/root/shared-hooks'

export const MainHeaderNavBar = ({ menuItems }: HeaderNavBarProps) => {
  const { oldBoraOrigin } = useConfig()
  return (
    <MainHeaderNavBarContainer>
      {menuItems.map(({ id, subject, link, modal }) => (
        <MenuItem href={!modal ? `${oldBoraOrigin}/#${link}` : ''} text={subject} key={id} type={'main'} />
      ))}
    </MainHeaderNavBarContainer>
  )
}
