import { FC, PropsWithChildren, ButtonHTMLAttributes } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'contained' | 'default'
  className?: string
}

const buttonVariants = {
  contained: 'font-bold text-white bg-adm-cyan rounded-lg py-4 px-8 darken',
  default: 'font-bold text-adm-cyan darken',
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({ children, variant = 'default', className, ...props }) => {
  return (
    <button className={`block ${buttonVariants[variant]} ${className}`} {...props}>
      {children}
    </button>
  )
}

export default Button
