import { ChevronDownIcon } from '@/root/ui/bluewave/icons/ChevronDownIcon'
import { FC, PropsWithChildren } from 'react'

interface DropdownButtonProps extends PropsWithChildren {
  onClick(): void
}

const DropdownButton: FC<DropdownButtonProps> = ({ children, onClick }) => {
  return (
    <button
      className="flex items-center justify-center gap-2 text-lg font-bold text-bw-primary-mediumLight "
      onClick={onClick}
    >
      {children}
      <ChevronDownIcon />
    </button>
  )
}

export default DropdownButton
