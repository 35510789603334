import { FC } from 'react'
import { UpperFooterItem } from '@/root/shared-types'
import FooterTopItem from '@/root/modules/bluewave/AppLayout/components/FooterTopItem/FooterTopItem'
import { useConfig } from '@/root/shared-hooks'

interface FooterTopProps {
  footerItems: UpperFooterItem[]
}

export const FooterTop: FC<FooterTopProps> = ({ footerItems }) => {
  const { oldBoraOrigin } = useConfig()
  return (
    <div className="bg-bw-primary-base text-white ">
      <div className="mx-auto flex max-w-screen-xl flex-wrap justify-around py-[50px]">
        {footerItems.map(({ title, content }) => (
          <FooterTopItem title={title} content={content} key={title} oldBoraOrigin={oldBoraOrigin} />
        ))}
      </div>
    </div>
  )
}
