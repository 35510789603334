import { FC } from 'react'
import { ClientLogo } from '@/root/ui/shared'
import { FooterBottomContainer } from '@/root/components/bluewave/FooterBottomContainer/FooterBottomContainer'

export const FooterBottom: FC = () => {
  const currentYear = new Date().getFullYear()
  return (
    <FooterBottomContainer>
      <ClientLogo clientName="bluewave" height={30} width={170} />
      <p className="text-sm text-bw-primary-veryLight">{`©${currentYear} Blue Wave Corp. | Powered by Bora Systems`}</p>
    </FooterBottomContainer>
  )
}
