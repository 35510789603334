import { FC, PropsWithChildren } from 'react'

interface IIfProps extends PropsWithChildren {
  condition: boolean
}

const If: FC<IIfProps> = ({ condition = true, children }) => {
  if (!condition) {
    return null
  }

  return <>{children}</>
}

export default If
