import { FC } from 'react'
import { useIntl } from 'react-intl'
import { currentMessages } from '@/lang'

interface PaymentInfoProps {
  ticketNumber: number
  paymentAmount: string
}
export const PaymentInfo: FC<PaymentInfoProps> = ({ ticketNumber, paymentAmount }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="space-y-5 rounded-md border border-bw-neutral-dark bg-bw-neutral-lightest py-5 text-center font-bold text-black">
      <h1 className="text-lg">{formatMessage(currentMessages.ticketPaymentInfoTitle).toUpperCase()}</h1>
      <div className="flex justify-center gap-5">
        <div className="flex-col text-right font-normal">
          <p>{formatMessage(currentMessages.ticketPaymentRecipient)}</p>
          <p>{formatMessage(currentMessages.ticketPaymentRecipientBankAccountNr)}</p>
          <p>{formatMessage(currentMessages.ticketPaymentRecipientBank)}</p>
          <p>{formatMessage(currentMessages.ticketPaymentRecipientBankCode)}</p>
          <p>{formatMessage(currentMessages.ticketPaymentDescription)}</p>
          <p>{formatMessage(currentMessages.ticketPaymentAmount)}</p>
        </div>
        <div className="flex-col text-left font-normal">
          <p>Blue Wave Corp.</p>
          <p>104343231</p>
          <p>BAC International Bank Inc</p>
          <p>BCINPAPA</p>
          <p>{`${formatMessage(currentMessages.ticketTicketNumber)}: ${ticketNumber}`}</p>
          <p>{paymentAmount}</p>
        </div>
      </div>
    </div>
  )
}
