import React, { FC } from 'react'
import Link from 'next/link'
import Image from 'next/image'

interface FooterSocialProps extends React.HTMLAttributes<HTMLDivElement> {
  socialItems: { icon: string; link: string }[]
}

const FooterSocial: FC<FooterSocialProps> = ({ socialItems, className }) => (
  <div className={`flex gap-[15px] ${className}`}>
    {socialItems.map(({ icon, link }) => (
      <Link href={link} key={link} target="_blank">
        <Image alt="social icon" src={`/bluewave/icons/${icon}.svg`} width={32} height={32} />
      </Link>
    ))}
  </div>
)

export default FooterSocial
