import { FC } from 'react'
import { FooterLinkAndInfoContentItem } from '@/root/shared-types'
import Link from 'next/link'
import classNames from 'classnames'
import FooterSocial from '@/root/modules/bluewave/AppLayout/components/FooterSocial/FooterSocial'

interface FooterTopItemProps {
  title: string
  content: FooterLinkAndInfoContentItem[]
  oldBoraOrigin?: string
}
const FooterTopItem: FC<FooterTopItemProps> = ({ title, content, oldBoraOrigin }) => (
  <div key={title} className="h-2/3 flex-col space-y-5">
    <h1 className="text-xl font-bold">{title}</h1>
    <div className="flex-col">
      {content.map(({ title, link, decoration, type, content }, index) => {
        if (link) {
          return (
            <Link className="block" key={index} href={oldBoraOrigin ? `${oldBoraOrigin}/#${link}` : ''}>
              {title}
            </Link>
          )
        }
        if (type === 'socialIcons' && content) {
          return <FooterSocial socialItems={content} key={index} className="mt-5" />
        }
        return (
          <p className={classNames({ 'font-bold mt-5': decoration === 'bold' })} key={index}>
            {title}
          </p>
        )
      })}
    </div>
  </div>
)

export default FooterTopItem
