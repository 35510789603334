import { FC, PropsWithChildren } from 'react'
import localFont from 'next/font/local'
import Header from '../Header/Header'
import { Footer } from '@/root/modules/bluewave/AppLayout/components/Footer/Footer'

const globalFont = localFont({
  src: [
    {
      path: '../../assets/fonts/Eina02-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/Eina02-Light.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/Eina02-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
  ],
})

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={`${globalFont.className} flex h-screen flex-col`}>
      <Header />
      <main className="grow bg-bw-ad-body-background">{children}</main>
      <Footer />
    </div>
  )
}

export default AppLayout
