import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

interface RowContainerProps extends PropsWithChildren {
  hideBorder?: boolean
  classnames?: string
}
export const RowContainer: FC<RowContainerProps> = ({
  children,
  hideBorder,
  classnames = 'items-center justify-between',
}) => (
  <div
    className={classNames(
      { 'border-b': Boolean(!hideBorder) },
      `max-[800px]:flex-col justify-center items-center space-y-3 p-3 ${classnames} md:py-1 flex`
    )}
  >
    {children}
  </div>
)
