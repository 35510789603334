import { FC } from 'react'
import { MainHeaderNavBar } from '@/root/modules/bluewave/AppLayout/components/MainHeaderNavBar/MainHeaderNavBar'
import { TopHeaderMenu } from '@/root/modules/bluewave/AppLayout/components/TopHeaderMenu/TopHeaderMenu'
import { useMenuItems, useStaticContent } from '@/root/shared-hooks'
import { getMenuItemsByLine } from '@/root/modules/admaritime/AppLayout/helpers/getMenuItemsByLine'

const Header: FC = () => {
  const { staticMenuItems } = useStaticContent()
  const { menuItems } = useMenuItems()

  const topHeaderMenuItems = getMenuItemsByLine(menuItems || staticMenuItems, 1)
  const mainHeaderMenuItems = getMenuItemsByLine(menuItems || staticMenuItems, 2)

  return (
    <header>
      <TopHeaderMenu menuItems={topHeaderMenuItems} />
      <MainHeaderNavBar menuItems={mainHeaderMenuItems} />
    </header>
  )
}

export default Header
