import { FC } from 'react'

const ListNumber: FC<{ number: number | string }> = ({ number }) => {
  return (
    <h1 className="flex h-[34px] w-[34px] min-w-[34px] justify-center rounded-full bg-white text-center text-xl text-adm-layer-section ">
      {number}
    </h1>
  )
}

export default ListNumber
