import classNames from 'classnames'
import Image from 'next/image'
import { FC } from 'react'

interface IIconProps {
  type: string
  alt: string
  width: number
  height?: number
  className?: string
  ignoreRtl?: boolean
}

const Icon: FC<IIconProps> = ({ type, alt, width, height = 0, className, ignoreRtl }) => {
  return (
    <Image
      className={classNames(
        {
          'rtl:[transform:rotateY(-180deg)]': !ignoreRtl,
        },
        className
      )}
      alt={alt}
      src={`/admaritime/icons/${type}.svg`}
      width={width}
      height={height}
    />
  )
}

export default Icon
