import { FC } from 'react'
import { RowContainer } from '@/root/modules/bluewave/PrintedTicket/compoonents/RowContainer/RowContainer'

interface RouteInfoProps {
  route: string
}
export const RouteInfo: FC<RouteInfoProps> = ({ route }) => (
  <RowContainer>
    <p className="text-lg font-bold">{route}</p>
  </RowContainer>
)
