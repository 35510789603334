import { FC } from 'react'
import { RowContainer } from '@/root/modules/bluewave/PrintedTicket/compoonents/RowContainer/RowContainer'
import { useIntl } from 'react-intl'
import { currentMessages } from '@/lang'
import { BookingStatus } from '@/root/shared-types/reservation/reservation-types'

interface CustomerInfoProps {
  email: string
  phoneNumber: string
  fullName: string
  reservationId: number
  status: BookingStatus
}

export const CustomerInfo: FC<CustomerInfoProps> = ({ email, phoneNumber, fullName, reservationId, status }) => {
  const isReservationPaid = status === 'OK'
  const { formatMessage } = useIntl()

  return (
    <RowContainer>
      <p className="text-2xl font-bold ">{fullName}</p>
      <div>
        <p className="text-center text-lg md:text-left">{phoneNumber}</p>
        <p className="break-all text-lg">{email}</p>
      </div>
      <div className="flex md:w-52 md:justify-end">
        <p>
          {formatMessage(currentMessages[isReservationPaid ? 'ticketTicketNumber' : 'ticketBookingNumber'])}{' '}
          <strong className="text-2xl">{reservationId}</strong>
        </p>
      </div>
    </RowContainer>
  )
}
