import { Variants } from '@/root/shared-types'
import { If } from '@/root/ui/admaritime'
import { FC, ReactNode } from 'react'
import { Severity } from '@/root/shared-types/ui/ui-types'

interface BannerProps {
  title?: string
  body: ReactNode
  variant: Severity
}

const bannerVariants: Variants = {
  success: {
    body: 'py-4 px-6 font-bold text-adm-green-700 bg-adm-green-200 border border-adm-green-700 rounded-md text-center',
    title: 'text-2xl mb-1',
    text: 'text-lg',
  },
  warning: {
    body: 'py-5 px-6 font-bold text-adm-brown-700 bg-adm-brown-200 border border-adm-brown-700 rounded-md text-center',
    title: 'text-2xl mb-1',
    text: 'text-lg',
  },
  error: {
    body: 'py-5 px-6  bg-adm-red-100 border border-adm-red-900 rounded-md text-center',
    title: 'text-2xl mb-1 font-bold text-adm-red-700',
    text: 'text-lg',
  },
  neutral: {
    body: 'px-6 py-4 text-center font-bold text-adm-green-700 text-center',
    title: 'text-2xl mb-1',
    text: 'text-lg',
  },
}

const Banner: FC<BannerProps> = ({ body, title, variant }) => {
  return (
    <article className={bannerVariants[variant].body}>
      <If condition={Boolean(title)}>
        <h2 className={bannerVariants[variant].title}>{title}</h2>
      </If>
      <p className={bannerVariants[variant].text}>{body}</p>
    </article>
  )
}

export default Banner
