import Image from 'next/image'
import { THEME } from '@/root/shared-types'
interface LogoProps {
  clientName: THEME
  width: number
  height: number
  altText?: string
}
const ClientLogo = ({ clientName, width, height, altText }: LogoProps) => {
  return (
    <Image alt={altText || `${clientName} logo`} src={`/${clientName}/images/logo.svg`} width={width} height={height} />
  )
}

export default ClientLogo
