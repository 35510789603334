import { FC } from 'react'
import AppLayout from '@/root/modules/bluewave/AppLayout/components/AppLayout/AppLayout'
import { usePrintedTicketData } from '@/root/shared-hooks'
import Banner from '@/root/ui/bluewave/Banner/Banner'
import { PrintedTicket, TicketActionButtons } from '@/root/modules/bluewave'
import { Loader } from '@/root/ui/bluewave'
import { currentMessages } from '@/lang'
import { useIntl } from 'react-intl'
import { WarningIcon } from '@/root/ui/bluewave/icons/WarningIcon'

export const TicketPage: FC = () => {
  const { reservation, error, isLoading } = usePrintedTicketData()
  const { formatMessage } = useIntl()

  const renderTicketContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center py-40">
          <Loader />
        </div>
      )
    }
    if (error) {
      return (
        <div className="m-10 mx-auto flex max-w-7xl flex-col bg-white p-10">
          <Banner
            title={formatMessage(currentMessages.ticketBannerErrorTitle)}
            body={formatMessage(currentMessages.ticketBannerErrorBody)}
            variant="error"
          />
        </div>
      )
    }
    if (reservation) {
      const isReservationPaid = reservation.status === 'OK'
      return (
        <div className="m-10 mx-auto flex  max-w-7xl flex-col gap-y-6 bg-white p-3 md:px-16 md:py-10 ">
          {isReservationPaid ? (
            <div>
              <Banner
                variant="neutral"
                title={formatMessage(currentMessages.ticketBannerTitle)}
                body={formatMessage(currentMessages.ticketBannerBody)}
              />

              <Banner variant="warning">
                <div className="flex items-center">
                  <span className="hidden md:block">
                    <WarningIcon />
                  </span>
                  <div className="w-full">
                    <h2 className="mb-2 text-2xl">Additional data is required to be filled before Check-in!</h2>
                    <button className="rounded-md bg-bw-secondary-base px-10 py-2 font-bold text-white">
                      REVIEW REQUIRED DATA
                    </button>
                  </div>
                </div>
              </Banner>
            </div>
          ) : (
            <Banner
              title={formatMessage(currentMessages.ticketBookingAwaitingPaymentTitle)}
              body={formatMessage(currentMessages.ticketBookingAwaitingPaymentBody)}
              variant="warning"
            />
          )}

          {reservation.status === 'OK' && <TicketActionButtons reservationId={reservation.reservationId} />}

          <PrintedTicket ticketData={reservation} />
        </div>
      )
    }
    return null
  }

  return <AppLayout>{renderTicketContent()}</AppLayout>
}

export default TicketPage
