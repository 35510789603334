import { FC } from 'react'
import classNames from 'classnames'

interface DividerProps {
  cancelled: boolean
}

const Divider: FC<DividerProps> = ({ cancelled }) => {
  const classesHr = cancelled ? 'h-[5px] bg-bw-error-dark flex-grow' : 'h-[1px] bg-black flex-grow'

  return (
    <div
      className={classNames({
        'text-bw-error-base': cancelled,
      })}
    >
      <div className={classesHr}></div>
      <svg width="65" viewBox="0 0 45 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="px-2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9287 19.4829L25.6141 24.0669C25.8289 24.2881 25.8232 24.6364 25.6028 24.8466C25.3824 25.0511 25.0376 25.0511 24.8172 24.8466L19.1653 19.317C18.9449 19.1013 18.9449 18.753 19.1653 18.5373L24.8172 13.0077C25.0432 12.7976 25.3993 12.8031 25.6141 13.0188C25.8232 13.2344 25.8232 13.5717 25.6141 13.7874L20.9287 18.377H37.6074C41.0606 18.377 43.8696 15.402 43.8696 11.7415C43.8696 8.08084 41.0606 5.10595 37.6074 5.10595H32.5828C32.3002 5.10595 32.0459 4.90689 32.0063 4.63592C31.9555 4.2931 32.2268 4 32.5659 4H37.6074C41.6824 4 45 7.4726 45 11.7415C45 16.0103 41.6824 19.4829 37.6074 19.4829H20.9287Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0713 5.51714H7.39265C3.31763 5.51714 0 8.98974 0 13.2585C0 17.5274 3.31763 21 7.39265 21H12.4341C12.7732 21 13.0445 20.7069 12.9937 20.3641C12.9541 20.0931 12.6998 19.894 12.4172 19.894H7.39265C3.93937 19.894 1.13036 16.9192 1.13036 13.2585C1.13036 9.59797 3.93937 6.62304 7.39265 6.62304H24.0713L19.3859 11.2126C19.1768 11.4283 19.1768 11.7656 19.3859 11.9812C19.6007 12.1969 19.9568 12.2024 20.1828 11.9923L25.8347 6.4627C26.0551 6.24704 26.0551 5.89868 25.8347 5.68303L20.1828 0.153419C19.9624 -0.0511398 19.6176 -0.0511398 19.3972 0.153419C19.1768 0.363579 19.1711 0.711943 19.3859 0.933092L24.0713 5.51714Z"
          fill="currentColor"
        />
      </svg>
      <div className={classesHr}></div>
    </div>
  )
}

export default Divider
