import { Variants } from '@/root/shared-types'
import { If } from '@/root/ui/admaritime'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { Severity } from '@/root/shared-types/ui/ui-types'

interface BannerProps extends PropsWithChildren {
  title?: string
  body?: ReactNode
  variant: Severity
}

const bannerVariants: Variants = {
  success: {
    body: 'py-4 px-7 font-bold text-bw-success-dark bg-bw-success-veryLight border border-bw-success-dark rounded-md text-center',
    title: 'text-2xl mb-1',
    text: 'text-lg',
  },
  warning: {
    body: 'py-4 px-7 font-bold text-bw-complementary-dark bg-bw-warning-Normal-lighter border border-bw-complementary-base rounded-md text-center',
    title: 'text-2xl mb-1',
    text: 'text-lg',
  },
  error: {
    body: 'py-4 px-7 bg-bw-error-lightest border border-bw-error-darkest rounded-md text-center',
    title: 'text-2xl mb-1 font-bold text-bw-error-dark',
    text: 'text-lg',
  },
  neutral: {
    body: 'py-4 px-7 text-center font-bold  text-bw-success-dark',
    title: 'text-3xl mb-1',
    text: 'text-lg',
  },
}

const Banner: FC<BannerProps> = ({ body, title, variant, children }) => {
  return (
    <article className={bannerVariants[variant].body}>
      <If condition={Boolean(title)}>
        <h2 className={bannerVariants[variant].title}>{title}</h2>
      </If>
      <p className={bannerVariants[variant].text}>{body}</p>
      {children}
    </article>
  )
}

export default Banner
