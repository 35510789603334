import { FC, MouseEventHandler } from 'react'
import { DownloadIcon } from '@/root/ui/bluewave/icons/DownloadIcon'
import { PrintIcon } from '@/root/ui/bluewave/icons/PrintIcon'
import { EditIcon } from '@/root/ui/bluewave/icons/EditIcon'
import { CrossIcon } from '@/root/ui/bluewave/icons/CrossIcon'
import { useIntl } from 'react-intl'
import { currentMessages } from '@/lang'
import { ActionVariant, StyleRecord } from '@/root/shared-types/ui/ui-types'

interface ActionButtonProps {
  action: ActionVariant
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const ActionButton: FC<ActionButtonProps> = ({ action, onClick }) => {
  const { formatMessage } = useIntl()
  const style: StyleRecord = {
    download: {
      title: formatMessage(currentMessages.ticketActionButtonsDownload),
      color: 'text-bw-secondary-base',
      icon: <DownloadIcon width={24} />,
    },
    print: {
      title: formatMessage(currentMessages.ticketActionButtonsPrint),
      color: 'text-bw-secondary-base',
      icon: <PrintIcon width={24} />,
    },
    edit: { title: 'Edit', color: 'text-bw-secondary-base', icon: <EditIcon /> },
    cancel: { title: 'Cancel ticket', color: 'text-bw-error-base', icon: <CrossIcon /> },
  }

  return (
    <button className={`${style[action].color} flex h-6 gap-3 text-sm`} onClick={onClick}>
      {style[action].icon}
      {style[action].title}
    </button>
  )
}
