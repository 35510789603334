import { FC } from 'react'
import { PageProps } from '@/root/shared-types'
import { StaticContentProvider } from '@/root/shared-providers'
import AppLayout from '@/root/modules/bluewave/AppLayout/components/AppLayout/AppLayout'

const MainPage: FC<PageProps> = ({ footerItems, menuItems }) => {
  return (
    <StaticContentProvider
      value={{
        staticFooterItems: footerItems,
        staticMenuItems: menuItems,
      }}
    >
      <AppLayout>Main page content</AppLayout>
    </StaticContentProvider>
  )
}

export default MainPage
