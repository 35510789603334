import { FC } from 'react'
import { FooterBottom } from '@/root/modules/bluewave/AppLayout/components/FooterBottom/FooterBottom'
import { FooterTop } from '@/root/modules/bluewave/AppLayout/components/FooterTop/FooterTop'
import { useFooterItems, useStaticContent } from '@/root/shared-hooks'

export const Footer: FC = () => {
  const { staticFooterItems } = useStaticContent()

  const { footerItems } = useFooterItems()
  const { upper = [] } = staticFooterItems || footerItems || {}

  return (
    <footer>
      <FooterTop footerItems={upper} />
      <FooterBottom />
    </footer>
  )
}
