import { useRouter } from 'next/router'
import { useRef } from 'react'

export const useActionsButtonHandlers = (reservationId: number) => {
  const {
    locale,
    query: { reservationToken },
    push,
  } = useRouter()

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const frameId = 'ticket-frame'

  const previewUrl = `/api/reservation/${reservationId}/preview/${locale}/${reservationToken}`

  const onDownload = () => window.open(previewUrl, '_blank')

  const onPrint = () => iframeRef.current?.contentWindow?.print()

  const onCancel = () => push(`/refund/${reservationToken}`)

  const iframeProps = {
    id: frameId,
    name: frameId,
    title: frameId,
    ref: iframeRef,
    className: 'hidden',
    src: previewUrl,
  }

  return { onDownload, onPrint, onCancel, iframeProps }
}
