import { ReservationItem, Vehicle } from '@/root/shared-types'
import Image from 'next/image'
import { FC } from 'react'
import { RowContainer } from '@/root/modules/bluewave/PrintedTicket/compoonents/RowContainer/RowContainer'
import { useIntl } from 'react-intl'
import { currentMessages } from '@/lang'
interface TicketItemsWithQRProps {
  items: ReservationItem[]
  reservationId: number
  sailRefId: number
  vehicles?: Vehicle[]
  displayQR: boolean
  hideBorder?: boolean
}

const TicketItemsWithQR: FC<TicketItemsWithQRProps> = ({
  items,
  reservationId,
  sailRefId,
  vehicles,
  displayQR,
  hideBorder,
}) => {
  const { formatMessage } = useIntl()
  return (
    <RowContainer hideBorder={hideBorder} classnames="items-start justify-between">
      <div className="flex flex-col space-y-4 text-lg">
        {items.map(({ priceCategoryTranslation, quantity }) => {
          return (
            <div key={priceCategoryTranslation}>
              {quantity} x {priceCategoryTranslation}
            </div>
          )
        })}
      </div>
      <div className="flex flex-col gap-5">
        {displayQR && (
          <div className="max-w-[300px] md:max-w-[140px]">
            <Image
              alt="qr"
              src={`/api/reservation/${reservationId}/sail/${sailRefId}/qr`}
              width={500}
              height={500}
              className="ml-auto mr-2"
            />
          </div>
        )}

        {vehicles &&
          vehicles.map((vehicle) => (
            <p key={vehicle.licencePlate} className="text-lg">
              {formatMessage(currentMessages.ticketVehicleRegNumber)} <strong>{vehicle.licencePlate}</strong>
            </p>
          ))}
      </div>
    </RowContainer>
  )
}

export default TicketItemsWithQR
