import { FC } from 'react'

interface TermsAndConditionsProps {
  conditionsInnerHTML: TrustedHTML | string
}
export const TermsAndConditions: FC<TermsAndConditionsProps> = ({ conditionsInnerHTML }) => {
  return (
    <div className="mt-6 flex gap-5 text-sm">
      <div dangerouslySetInnerHTML={{ __html: conditionsInnerHTML }} />
    </div>
  )
}
