import { FC } from 'react'
import { ShipIcon } from '@/root/ui/bluewave/icons/ShipIcon'
import { PassengerIcon } from '@/root/ui/bluewave/icons/Passenger'
import { CarIcon } from '@/root/ui/bluewave/icons/CarIcon'
import { RowContainer } from '@/root/modules/bluewave/PrintedTicket/compoonents/RowContainer/RowContainer'

interface DepartureAndVesselInfoProps {
  departureValue: string
  vesselTitleThere: string
  numOfPassengersThere: number
  numOfCarsThere: number
}

export const DepartureAndVesselInfo: FC<DepartureAndVesselInfoProps> = ({
  departureValue,
  vesselTitleThere,
  numOfPassengersThere,
  numOfCarsThere,
}) => (
  <RowContainer>
    <p className="text-xl font-bold">{departureValue}</p>
    <div className="flex gap-3 ">
      <ShipIcon />
      <p className="text-lg font-bold md:text-2xl">{vesselTitleThere.toUpperCase()}</p>
    </div>

    <div className="sm:w-48 flex gap-3 md:justify-end">
      {Boolean(numOfPassengersThere) && (
        <div className="flex gap-3">
          <PassengerIcon />
          <p className="text-lg font-bold">{` x ${numOfPassengersThere}`}</p>
        </div>
      )}

      {Boolean(numOfCarsThere) && (
        <div className="flex gap-3 ">
          <CarIcon />
          <p className="text-lg font-bold">{` x ${numOfCarsThere}`}</p>
        </div>
      )}
    </div>
  </RowContainer>
)
